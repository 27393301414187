import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import useLottie from 'hooks/use-lottie';

const tabs = [
  {
    id: 'withYobi',
    name: 'With Yobi',
  },
  {
    id: 'withoutYobi',
    name: 'Without Yobi',
  },
];

const ANIMATION_TIME_WITH_YOBI = 250;
const ANIMATION_TIME_WITHOUT_YOBI = 500;

const Scheme = ({ className, title, description, linkText, linkUrl, animationData, withTabs }) => {
  const [activeTabId, setActiveTabId] = useState(tabs[0].id);

  const [animationVisibilityRef, inView] = useInView({
    threshold: 0.2,
  });

  const [isAnimationReady, setIsAnimationReady] = useState(false);

  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
    }
  );

  const handleAnimationFrame = useCallback(
    ({ currentTime }) => {
      if (
        currentTime >= ANIMATION_TIME_WITH_YOBI &&
        currentTime < ANIMATION_TIME_WITHOUT_YOBI &&
        activeTabId !== tabs[0].id
      ) {
        return setActiveTabId(tabs[0].id);
      }

      if (
        (currentTime < ANIMATION_TIME_WITH_YOBI || currentTime >= ANIMATION_TIME_WITHOUT_YOBI) &&
        activeTabId !== tabs[1].id
      ) {
        return setActiveTabId(tabs[1].id);
      }
      return null;
    },
    [activeTabId]
  );

  const handleTabClick = (id) => () => setActiveTabId(id);

  useEffect(() => {
    if (!animation || !isAnimationReady) return;
    if (inView) {
      animation.play();
    } else {
      animation.pause();
    }
  }, [animation, isAnimationReady, inView]);

  useEffect(() => {
    if (!animation || !isAnimationReady || !withTabs) return;
    // if there are tabs in the block,
    // then we set the frame for the animation with which it should start
    if (activeTabId === tabs[0].id) {
      animation.goToAndPlay(ANIMATION_TIME_WITH_YOBI, true);
    } else {
      animation.goToAndPlay(ANIMATION_TIME_WITHOUT_YOBI, true);
    }
    // we track the frames and make the desired tab active
    animation.addEventListener('enterFrame', handleAnimationFrame);
    return () => {
      animation.removeEventListener('enterFrame', handleAnimationFrame);
    };
  }, [animation, isAnimationReady, withTabs, activeTabId, handleAnimationFrame]);

  return (
    <section ref={animationVisibilityRef}>
      <Container className={className}>
        <div className="px-4 py-10 text-center text-white sm:px-10 lg:py-15 gradient-background-blue lg:px-24 rounded-2xl">
          <Heading
            className="relative z-10 max-w-[900px] mx-auto text-3xl md:text-4xl lg:text-5xl"
            tag="h1"
            size="md"
            theme="white"
          >
            {title}
          </Heading>
          {description && (
            <p className="mt-5 text-lg lg:text-xl lg:leading-normal max-w-[800px] mx-auto">
              {description}
            </p>
          )}
          {linkUrl && (
            <Link
              className="relative z-10 mt-8"
              theme="primary-1"
              type="arrow"
              to="/personalization"
            >
              {linkText}
            </Link>
          )}
          <div className="flex justify-center items-center h-auto mt-8">
            <div ref={animationRef} />
          </div>

          {withTabs && (
            <div
              className={classNames(
                'relative grid grid-cols-2 items-center mx-auto max-w-[332px] mt-8 border-opacity-25 rounded-4xl border-2 border-gray-4',
                'before:absolute before:top-0 before:w-1/2 before:h-full before:bg-primary-1 before:rounded-4xl before:left-0 before:transition-left before:duration-300',
                {
                  'before:left-0 ': tabs[0].id === activeTabId,
                },
                {
                  'before:left-1/2': tabs[1].id === activeTabId,
                }
              )}
            >
              {tabs.map(({ id, name }) => (
                <Button
                  className="relative rounded-4xl px-0 justify-center leading-none text-sm xs:text-base"
                  size="sm"
                  key={id}
                  onClick={handleTabClick(id)}
                >
                  {name}
                </Button>
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

Scheme.defaultProps = {
  className: '',
  description: null,
  linkText: 'Learn more',
  linkUrl: null,
  animationData: null,
  withTabs: false,
};

Scheme.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  animationData: PropTypes.objectOf(PropTypes.any),
  withTabs: PropTypes.bool,
};

export default Scheme;
