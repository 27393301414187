import Lottie from 'lottie-web';
import { useState, useRef, useEffect } from 'react';

export default function useLottie(options, events = {}) {
  const animationRef = useRef();
  const [animation, setAnimation] = useState();

  useEffect(() => {
    const lottieAnimation = Lottie.loadAnimation({
      container: animationRef.current,
      ...options,
    });
    Object.entries(events).forEach(([eventName, cb]) => {
      lottieAnimation.addEventListener(eventName, cb.bind(lottieAnimation));
    });
    setAnimation(lottieAnimation);
    return () => {
      lottieAnimation.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [animation, animationRef];
}
